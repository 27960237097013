<template>
  <section title="">
    <vue-confirm-dialog />
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col
                md="8"
              >
                <h2>Carteira de Fiado</h2>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.ordersModal
                        variant="outline-success"
                        size="sm"
                        class="btn-icon"
                        @click="modalData(item)"
                      >
                        Ver pedidos
                      </b-button>
                    </slot>
                    <slot v-if="column.label == 'Status'">
                      <b-badge
                        :variant="nomeStatus(item).cor"
                      >
                        {{ nomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                    <slot v-if="column.label == 'Valor'">
                      {{ valorTotal(item.payment) }}
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
      <b-modal
        v-if="mostraPopUp"
        id="ordersModal"
        ref="ordersModal"
        title="Pedidos da Carteira"
        hide-footer
        centered
      >
        <div
          v-if="registers.length > 0"
        >
          <div class="border pt-50 pb-50">
            <b-row>
              <b-col
                class="pl-2 text-center"
                md="5"
              >
                Data
              </b-col>
              <b-col
                md="4"
                class="text-center"
              >
                Valor
              </b-col>
              <b-col
                md="3"
                class="text-center"
              >
                Pagar
              </b-col>
            </b-row>
          </div>
          <div class="border p-50">
            <b-row
              v-for="register in registers"
              :key="register.id"
            >
              <b-col
                class="pl-2 text-center mt-25"
                md="5"
              >
                {{ register.created_at | moment("DD/MM/YY HH:mm:ss") }}
              </b-col>
              <b-col
                md="4"
                class="text-center mt-25"
              >
                R$ {{ register.totalOrder ? register.totalOrder : 'Sem Valor' }}
              </b-col>
              <b-col
                md="3"
                class="text-center"
              >
                <b-button
                  variant="flat-success"
                  class="btn-icon"
                  size="sm"
                  @click="warningCard(register)"
                >
                  <feather-icon icon="DollarSignIcon" />
                </b-button>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-alert
            variant="success"
            show
          >
            <div class="alert-heading text-center">
              <p><strong>Sem Débitos</strong></p>
            </div>
            <div class="alert-body text-center">
              <p>Este cliente não tem débitos pendentes no sistema.</p>
              <br>
              <p class="small">
                Caso tenha o pedido em mãos e não aparecer aqui, verifique na aba pedidos se o mesmo foi registrado como fiado.
              </p>
              <br>
              <p class="small">
                Caso algum pagamento tenha sido registrado de maneira incorreta entre em contato com o suporte.
              </p>
            </div>
          </b-alert>
        </div>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="closeModal"
            >
              fechar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
  </section>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      id: null,
      categoriaTipo: '',
      nomeCategorias: '',
      descCategorias: '',
      mostraPopUp: true,
      status: '',
      url: 'customers/getwallets',
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
      columns: [
        {
          label: 'Cliente',
          name: 'name',
          orderable: true,
        },
        {
          label: 'telefone',
          name: 'phone',
          orderable: true,
        },
        {
          label: 'Valor',
          orderable: true,
        },
        {
          label: 'Status',
          orderable: true,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
      registers: [],
    }
  },
  created() {
    this.BuscarDados(this.url)
  },
  methods: {

    warningCard(item) {
      this.$swal({
        title: 'ATENÇÃO!',
        text: 'Confirma o pagamento deste pedido ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary mr-25',
          cancelButton: 'btn btn-danger ml-25',
        },
        buttonsStyling: false,
      }).then(resp => {
        if (resp.isConfirmed) {
          this.$http.post(`payments/orderwallet/${item.id}`, item).then(response => {
            if (response.data.success) {
              this.$refs.ordersModal.hide()
              this.reloadTable()
              this.$swal({
                title: 'CONFIRMADO!',
                text: 'O pagamento deste pedido foi confirmado!',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              })
            } else {
              this.$swal({
                title: 'ERRO!',
                text: 'Erro ao registrar o pagamento, contate o suporte para evitar conflitos!',
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
              })
            }
          })
        } else {
          this.$swal({
            title: 'CANCELADO!',
            text: 'Ação cancelada, nenhuma alteração feita!',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      })
    },
    async modalData(item) {
      this.registers = []
      item.payment.forEach(order => {
        const obj = {
          id: order.id,
          tinyId: order.tinyId,
          totalOrder: order.totalOrder,
          created_at: order.created_at,
        }
        this.registers.push(obj)
      })
    },
    valorTotal(pedido) {
      let valor = 0
      pedido.forEach(pede => {
        valor += Number(pede.totalOrder)
      })
      return valor
    },
    nomeStatus(obj) {
      if (obj.payment.length <= 0) {
        return { cor: 'light-success', msg: 'Sem Débitos' }
      }
      if (obj.status === 3) {
        return { cor: 'light-danger', msg: 'Vencido' }
      } return { cor: 'light-secondary', msg: 'Sem Status' }
    },
    async EditarDados(item) {
      this.$confirm(
        {
          message: 'Este pagamento foi efetuado ?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              this.$loading(true)
              await this.$http.post('carteira_pago', item)
              this.$toast.success('Pagamento do fiado registrado')
              this.reloadTable()
            }
          },
        },
      )
    },
    closeModal() {
      this.$refs.ordersModal.hide()
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      this.$loading(false)
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    DeletarDados(item) {
      this.$confirm(
        {
          message: 'Para isso os pedidos em fiado deste cliente serão cancelados. Tem certeza ?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              this.$loading(true)
              await this.$http.post('deletarconta', item)
              this.$toast.success('Pagamento do fiado registrado')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>
